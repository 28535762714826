import stylesheet from "./ShoppingForm.module.scss";
import React, { ChangeEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTextColor } from "../../../FiraChat/utils/colorUtils";
import {
	CloseButtonX,
	WhatsappFlat,
	WhatsappLine,
} from "../../../components/FiraIcons";
import { useChatContext } from "../../../FiraChat/providers/ChatProvider";
import { CartProduct, useCart } from "../../../context/CartContext";
import { DataContext } from "../../../context/DataContext";
import currencyFormat from "../../../utils/currencyFormat";
import { ShoppingCartRequestBody } from "../../../utils/types";
import { sendShoppingCart } from "../../../utils/actions";

const {
	ShoppingFormWrapper,
	FormWrapper,
	Title,
	DisclaimerWrapper,
	TextInput,
	ShoppingButton,
	Top,
	Disabled,
} = stylesheet;

interface ShoppingFormProps {
	onClose(): void;
	store?: string;
}

const ShoppingForm = ({ onClose, store }: ShoppingFormProps) => {
	const urlParams = new URLSearchParams(window.location.search);
	const store_number = urlParams.get("phoneNumber") || "";
	const { t } = useTranslation();
	const { getChatConfig } = useChatContext();
	const { mainColor } = getChatConfig();
	const { cart } = useCart();
	const { broadcast, queues }: any = useContext(DataContext);
	const { currency } = broadcast;
	const fira_src = urlParams.get("firasrc") || "";
	const [name, setName] = useState<string>("");
	const [disclaimerAccepted, setDisclaimerAccepted] = useState<boolean>(false);

	const calculateSumTotal = (products: CartProduct[]): number => {
		let sum = 0;
		for (let i = 0; i < products.length; i++) {
			const item = products[i];
			const price =
				item.price2 && parseFloat(item.price2) > 0
					? parseFloat(item.price2)
					: parseFloat(item.price1);
			sum += price * item.quantity;
		}
		return sum;
	};

	const trimmer = (inputString: string) =>
		/\s$/.test(inputString) ? inputString.trimEnd() : inputString;

	const wsMessage = `¡Hola! soy ${name},
Te envío mi lista de compras:
 ${cart
		.map(
			product =>
				`
  *${trimmer(product.name)}*
  *${trimmer(product.variationName ? product.variationName : "")}*
  *Cantidad*: ${product.quantity} / ${String(
					currencyFormat(
						product.price2 !== undefined && parseFloat(product.price2) > 0
							? parseFloat(product.price2) * product.quantity
							: parseFloat(product.price1) * product.quantity,
						currency
					)
				)}
  ---------------------------
`
		)
		.join("")}
  TOTAL: ${String(currencyFormat(calculateSumTotal(cart ?? []), currency))}
  ¡Gracias!`;

	const cleanedMessage = wsMessage
		.replace(/\n/g, "%0A")
		.replace(/:/g, "%3A")
		.replace(/ /g, "%20");

	const whatsappLink = `https://wa.me/${store_number}?text=${cleanedMessage}`;

	const openLink = async (e: React.MouseEvent<HTMLButtonElement>) => {
		window.open(whatsappLink, "_blank", "noreferrer");
		e.preventDefault();

		const partialCart = cart.map<ShoppingCartRequestBody>(p => {
			const price = p.price2
				? parseFloat(p.price2) > 0 &&
				  parseFloat(p.price2) < parseFloat(p.price1)
					? p.price2
					: p.price1
				: p.price1;
			return {
				BroadcastingProduct: {
					id: p.sku,
					name: p.name,
					price,
				},
				quantity: p.quantity,
			};
		});

		await sendShoppingCart(fira_src, partialCart, queues.connectionId, name);
	};

	return (
		<div className={`${ShoppingFormWrapper}`}>
			<div className={`${FormWrapper}`}>
				<form>
					<div className={Top}>
						<span className={Title}>
							{t("components.shopping_cart.budget")}
						</span>
						<CloseButtonX
							color={"#D9D9D9"}
							style={{ cursor: "pointer" }}
							onClick={onClose}
						/>
					</div>
					<input
						type="text"
						className={TextInput}
						placeholder={t("components.shopping_cart.namePlaceholder")}
						onChange={e => setName(e.target.value)}
					/>
					<div className={DisclaimerWrapper}>
						<input
							type="checkbox"
							id="disclaimerAccepted"
							name="disclaimerAccepted"
							checked={disclaimerAccepted}
							onChange={() => setDisclaimerAccepted(!disclaimerAccepted)}
						/>
						<label htmlFor="disclaimerAccepted">
							<span>{t("components.login_user.disclaimer_1")}</span>{" "}
							<span>{t("components.login_user.disclaimer_2")}</span>{" "}
							<a
								href="https://marykay.com.co/Aviso-de-Privacidad"
								target="_blank"
							>
								{t("components.login_user.disclaimer_3")}
							</a>
						</label>
					</div>
					{name === "" || !disclaimerAccepted ? (
						<button onClick={openLink} className={`${Disabled}`}>
							<WhatsappFlat width={24} height={24} color={"#fff"} />
							<span>{t("components.shopping_cart.buyButtonText")}</span>
						</button>
					) : (
						<button
							onClick={openLink}
							className={`${ShoppingButton}`}
							style={{
								backgroundColor: mainColor,
								color: getTextColor(mainColor),
							}}
						>
							<WhatsappFlat
								width={24}
								height={24}
								color={getTextColor(mainColor)}
							/>
							<span>{t("components.shopping_cart.buyButtonText")}</span>
						</button>
					)}
				</form>
			</div>
		</div>
	);
};

export default ShoppingForm;
