export const TRANSLATIONS_PT = {
	components: {
		loading_state: "Em breve uma nova Transmissão começará!",
		header: {
			share: "Compartilhar",
		},
		login_user: {
			title: " Login",
			participate: "Envie uma mensagem",
			disclaimer_1: "Eu aceito",
			disclaimer_2: "Termos de Serviços, Política de Privacidade e Cookies.",
			messages: {
				error: "Usuário existente, por favor tente com outros dados.",
			},
			form: {
				user: "Usuário",
			},
			accept: "Aceitar",
			cancel: "Cancelar",
		},
		message_popup: {
			not_started: {
				soon: "Começará em breve",
				hours: "Começará em {{hours}} horas.",
				days: "Começará em {{days}} dias.",
			},
			error: {
				title: "Erro.",
				message: "Algo deu errado, entre em contato com o suporte.",
			},
			no_broadcast: {
				title: "Não encontrado.",
				message: "Transmissão não encontrada.",
			},
			no_parameters: {
				title: "Os parâmetros estão ausentes.",
				message: "Validar o URL de transmissão.",
			},
			reconnecting: {
				title: "Reconectando...",
			},
			done: {
				title: "LIVE CONCLUÍDA...",
			},
			done_error: {
				title: "LIVE CONCLUÍDA...",
				message: "Houve um erro com o vídeo.",
			},
		},
		share_popup: {
			title: "Compartilhar link",
			share: "Compartilhar",
			copy: "Copiar",
			copied: "Copiado",
			copyLink: "Copiar link",
		},
		video_controls: {
			pip_message_1: "Minimizar",
			pip_message_2: "Vídeo",
			copied: "copiado",
			link: "Link",
		},
		custom_message_input: {
			input: "Escreva uma mensagem...",
		},
		chat: {
			empty_chat: "Envie uma mensagem para iniciar a conversa.",
		},
		product_popup: {
			buy: "Comprar",
		},
		share_message: "Te convido a participar da experiência live shopping em",
		shopping_cart: {
			buyButtonText: "Continuar compra",
			budget: "Enviar orçamento",
			namePlaceholder: "Digite seu nome*",
			empty: "Você não tem nenhum produto",
		}
	},
};
